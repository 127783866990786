<template>
  <div class="base-pagination-meta" v-if="meta.total_count">
    <span
      >Showing <span class="number">{{ start }}&mdash;{{ end }}</span> of
      <span class="number">{{ totalCountDisplay }}</span> {{ itemsName }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    meta: Object,
    itemsName: {type: String, default: 'results'},
  },
  computed: {
    start() {
      return this.meta.offset + 1;
    },
    end() {
      const expectedEnd = this.meta.offset + this.meta.limit;
      return this.meta.total_count > expectedEnd ? expectedEnd : this.meta.total_count;
    },
    totalCountDisplay() {
      if (this.meta.total_count >= 10000) {
        return '10000+';
      }
      return this.meta.total_count;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-pagination-meta {
  display: flex;
  align-items: center;
  color: $supplemental-text-color;
}
</style>
